<template>
  <div class="fieldset">
    <h3 class="fieldset-heading">Last Will & Testament</h3>

    <ExistingWillYN v-model="form.will_existing"/>

    <transition name="fade">
      <ExistingWillDraftsPerson v-if="willExistingDraftsPerson(form)" v-model="form.will_existing_drafts_person"/>
    </transition>
    <transition name="fade">
      <ExistingWillYear v-if="willExistingYear(form)" v-model="form.will_existing_year"/>
    </transition>
    <transition name="fade">
      <ExistingWillChangesRelationshipYN v-if="changesRelationship(form)" v-model="form.changes_relationship"/>
    </transition>
    <transition name="fade">
      <ExistingWillChangesRelationshipDetails v-if="changesRelationshipDetails(form)"
                                              v-model="form.changes_relationship_details"/>
    </transition>
    <transition name="fade">
      <ExistingWillChangesChildrenYN v-if="changesDependants(form)" v-model="form.changes_dependants"/>
    </transition>
    <transition name="fade">
      <ExistingWillChangesChildrenDetails v-if="changesDependantsDetails(form)"
                                          v-model="form.changes_dependants_details"/>
    </transition>
    <transition name="fade">
      <ExistingWillChangesExecutorsYN v-if="willChangesAppointed(form)" v-model="form.changes_appointed"/>
    </transition>
    <transition name="fade">
      <ExistingWillChangesExecutorsDetails v-if="willChangesAppointedDetails(form)"
                                           v-model="form.changes_appointed_details"/>
    </transition>
    <transition name="fade">
      <ExistingWillChangesEstateYN v-if="willChangesEstate(form)" v-model="form.changes_estate"/>
    </transition>
    <transition name="fade">
      <ExistingWillChangesEstateDetails v-if="willChangesEstateDetails(form)" v-model="form.changes_estate_details"/>
    </transition>
    <transition name="fade">
      <ExistingWillChangesOtherYN v-if="willChangesOther(form)" v-model="form.changes_will_other"/>
    </transition>
    <transition name="fade">
      <ExistingWillChangesOtherDetails v-if="willChangesOtherDetails(form)" v-model="form.changes_will_other_details"/>
    </transition>
    <ExistingWillOverseasYN v-if="willExistingOverseas(form)" v-model="form.will_existing_overseas"/>

  </div>
</template>

<script>
import { fieldsetHelpers} from "@/components/common/questionnaires/fieldsets/fieldsetHelpers";
import {dateFormat} from '@/mixins/dateFormat';
import { showClientQuestionHelpers } from "@/views/questionnaires/EstatePlanningSurvey/showClientQuestionHelpers";

import ExistingWillYN
  from "@/components/common/questionnaires/question/questions/estatePlanningSurvey/existingProducts/will/ExistingWillYN";
import ExistingWillYear
  from "@/components/common/questionnaires/question/questions/estatePlanningSurvey/existingProducts/will/ExistingWillYear";
import ExistingWillDraftsPerson
  from "@/components/common/questionnaires/question/questions/estatePlanningSurvey/existingProducts/will/ExistingWillDraftsPerson";
import ExistingWillChangesRelationshipYN
  from "@/components/common/questionnaires/question/questions/estatePlanningSurvey/existingProducts/will/ExistingWillChangesRelationshipYN";
import ExistingWillChangesRelationshipDetails
  from "@/components/common/questionnaires/question/questions/estatePlanningSurvey/existingProducts/will/ExistingWillChangesRelationshipDetails";
import ExistingWillChangesChildrenYN
  from "@/components/common/questionnaires/question/questions/estatePlanningSurvey/existingProducts/will/ExistingWillChangesChildrenYN";
import ExistingWillChangesChildrenDetails
  from "@/components/common/questionnaires/question/questions/estatePlanningSurvey/existingProducts/will/ExistingWillChangesChildrenDetails";
import ExistingWillChangesExecutorsYN
  from "@/components/common/questionnaires/question/questions/estatePlanningSurvey/existingProducts/will/ExistingWillChangesExecutorsYN";
import ExistingWillChangesExecutorsDetails
  from "@/components/common/questionnaires/question/questions/estatePlanningSurvey/existingProducts/will/ExistingWillChangesExecutorsDetails";
import ExistingWillChangesEstateYN
  from "@/components/common/questionnaires/question/questions/estatePlanningSurvey/existingProducts/will/ExistingWillChangesEstateYN";
import ExistingWillChangesEstateDetails
  from "@/components/common/questionnaires/question/questions/estatePlanningSurvey/existingProducts/will/ExistingWillChangesEstateDetails";
import ExistingWillChangesOtherYN
  from "@/components/common/questionnaires/question/questions/estatePlanningSurvey/existingProducts/will/ExistingWillChangesOtherYN";
import ExistingWillChangesOtherDetails
  from "@/components/common/questionnaires/question/questions/estatePlanningSurvey/existingProducts/will/ExistingWillChangesOtherDetails";
import ExistingWillOverseasYN
  from "@/components/common/questionnaires/question/questions/estatePlanningSurvey/existingProducts/will/ExistingWillOverseasYN";

export default {
  name: 'ExistingProductsWill',
  mixins: [fieldsetHelpers, showClientQuestionHelpers, dateFormat],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: {
    ExistingWillOverseasYN,
    ExistingWillChangesOtherDetails,
    ExistingWillChangesOtherYN,
    ExistingWillChangesEstateDetails,
    ExistingWillChangesEstateYN,
    ExistingWillChangesExecutorsDetails,
    ExistingWillChangesExecutorsYN,
    ExistingWillChangesChildrenDetails,
    ExistingWillChangesChildrenYN,
    ExistingWillChangesRelationshipDetails,
    ExistingWillChangesRelationshipYN,
    ExistingWillDraftsPerson,
    ExistingWillYear,
    ExistingWillYN,
  },
  data() {
    return {
      label: 'Will' // used for steps progress
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  }
};
</script>
