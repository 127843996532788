<template>
  <QuestionBase
    :question="question"
    :errors="[...serverErrors, ...jsErrors]"
    :valid="!!data"
  >
    <InputBasic
      v-model="data"
      :db="db"
      :args="args"
      :skipped="skipped"
      @skipped="skipped = $event"
      @serverErrors="serverErrors = $event"
      @jsErrors="jsErrors = $event"
    />
  </QuestionBase>
</template>

<script>
import { questionLoadHelpers } from "../../../../helpers/questionLoadHelpers";

import QuestionBase from "../../../../QuestionBase";
import InputBasic from "../../../../inputs/InputBasic";

export default {
  name: "ExistingWillYear",
  mixins: [questionLoadHelpers],
  components: {
    InputBasic,
    QuestionBase,
  },
  props: {
    value: {
      type: Number,
      required: false,
    },
  },
  computed: {
    data: {
      set(value) {
        this.$emit("input", value);
      },
      get() {
        return this.value;
      },
    },
  },
  data() {
    return {
      question: {
        title: "Approximately what year was the previous will executed?",
        subTitle: 'A will is executed when it is signed and witnessed.',
        tip: null,
      },
      db: {
        saveLocation: "estate_planning_survey_save",
        saveField: "will_existing_year",
      },
      args: {
        placeholder: "e.g. 1998",
        label: null,
        skippable: false,
        required: true,
        inputClass: "field-33",
        type: "number",
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
    };
  },
};
</script>