<template>
  <div class="fieldset">
    <h3 class="fieldset-heading">Lasting Power of Attorney</h3>

    <ExistingLpaYN v-model="form.lpa_existing"/>
    <transition name="fade">
      <ExistingLpaType v-if="lpaExistingType(form)" v-model="form.lpa_existing_type"/>
    </transition>
    <transition name="fade">
      <ExistingLpaChangesYN v-if="lpaChanges(form)" v-model="form.lpa_changes"/>
    </transition>
    <transition name="fade">
      <ExistingLpaChangesDetails v-if="lpaChangesDetails(form)" v-model="form.lpa_changes_details"/>
    </transition>

    <h3 v-if="funeralPlanHas(form)" class="fieldset-heading">Funeral</h3>

    <ExistingFuneralPlanYN v-if="funeralPlanHas(form)" v-model="form.funeral_plan_has"/>
    <FuneralFundsYN v-if="funeralPlanFunds(form)" v-model="form.funeral_plan_funds"/>

  </div>
</template>

<script>
import { fieldsetHelpers} from "@/components/common/questionnaires/fieldsets/fieldsetHelpers";
import {dateFormat} from '@/mixins/dateFormat';
import { showClientQuestionHelpers } from "@/views/questionnaires/EstatePlanningSurvey/showClientQuestionHelpers";

import ExistingLpaChangesYN
  from "@/components/common/questionnaires/question/questions/estatePlanningSurvey/existingProducts/lpa/ExistingLpaChangesYN";
import ExistingLpaType
  from "@/components/common/questionnaires/question/questions/estatePlanningSurvey/existingProducts/lpa/ExistingLpaType";
import ExistingFuneralPlanYN
  from "@/components/common/questionnaires/question/questions/estatePlanningSurvey/existingProducts/funeral/ExistingFuneralPlanYN";
import FuneralFundsYN
  from "@/components/common/questionnaires/question/questions/estatePlanningSurvey/existingProducts/funeral/FuneralFundsYN";
import ExistingLpaYN
  from "@/components/common/questionnaires/question/questions/estatePlanningSurvey/existingProducts/lpa/ExistingLpaYN";
import ExistingLpaChangesDetails
  from "@/components/common/questionnaires/question/questions/estatePlanningSurvey/existingProducts/lpa/ExistingLpaChangesDetails";

export default {
  name: 'ExistingProductsLpa',
  mixins: [fieldsetHelpers, showClientQuestionHelpers, dateFormat],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: {
    FuneralFundsYN,
    ExistingFuneralPlanYN,
    ExistingLpaType,
    ExistingLpaChangesDetails,
    ExistingLpaChangesYN,
    ExistingLpaYN
  },
  data() {
    return {
      label: 'LPA' // used for steps progress
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  }
};
</script>
