<template>
  <QuestionBase :question="question" :errors="[...serverErrors, ...jsErrors]" :valid="data !== null">
    <InputRadio
        v-model="data"
        :options="booleanYesNo"
        :db="db"
        :args="args"
        :skipped="skipped"
        @skipped="skipped=$event"
        @serverErrors="serverErrors=$event"
        @jsErrors="jsErrors=$event"
    />
  </QuestionBase>
</template>

<script>
import { questionLoadHelpers } from "../../../../helpers/questionLoadHelpers";
import { general } from "../../../../options/general";

import QuestionBase from "../../../../QuestionBase";
import InputRadio from "../../../../inputs/InputRadio";

export default {
  name: 'ExistingWillYN',
  mixins: [general, questionLoadHelpers],
  components: {
    InputRadio,
    QuestionBase
  },
  props: {
    value: {
      type: [Boolean],
      required: false
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get () {
        return this.value
      }
    }
  },
  data () {
    return {
      question: {
        title:  'Do you have a valid Last Will & Testament in place?',
        subTitle:  'A valid will is one that has been signed and witnessed and not revoked thereafter.',
        tip: `<p>A old will may have been revoked in various ways:
                <br><br>
                    <strong>Revocation by Destruction</strong>
                    An easy way to revoke a will is to destroy it. You can burn it, tear it, or shred it to pieces, so long as you intend to destroy the will. This applies to whether you actually destroy it, or whether someone else destroys it, at your request, and in your presence.
                <br><br>
                    <strong>Revocation by Making a New Will</strong>
                    A new will often revokes any earlier will / wills. The new will should be properly executed and reflect language that states your desire to revoke all prior wills, such as “I hereby revoke any and all old Wills that I have previously made.”
                <br><br>
                    <strong>Revocation by Marriage</strong>
                    A will made before a marriage is automatically revoked when the testator enters into a marriage or Civil Partnership (unless it appears from the will that the testator was expecting to marry a particular person at the time that the will was signed).
                <br><br>
                    <strong>Revocation by Deed</strong>
                    A will may be revoked by ‘some writing declaring an intention to revoke the same’. For revocation carried out in this way to be valid the writing must be duly executed attestation in the same manner as the will.
              </p>
              `,
      },
      db: {
        saveLocation: 'estate_planning_survey_save',
        saveField: 'will_existing'
      },
      args: {
        skippable: false,
        required: true,
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
    }
  }
}
</script>