export const estatePlanningSurveyOptions = {
    data () {
        return {
            willDraftsPersonOptions: [
                {
                    label: 'Dunham McCarthy',
                    value: 'Dunham McCarthy'
                },
                {
                    label: 'Other Professional',
                    value: 'Other Professional'
                },
                {
                    label: 'MyLastWill',
                    value: 'MyLastWill'
                },
                {
                    label: 'Other Online Platform',
                    value: 'Other Online Platform'
                },
                {
                    label: 'Homemade / DIY',
                    value: 'Homemade / DIY'
                }
            ],
            changesRelationshipOptions: [
                {
                    label: 'Married',
                    value: 'Married'
                },
                {
                    label: 'Divorced',
                    value: 'Divorced'
                },
                {
                    label: 'Separated',
                    value: 'Separated'
                },
                {
                    label: 'New Partner',
                    value: 'New Partner'
                },
                {
                    label: 'Partner Deceased',
                    value: 'Partner Deceased'
                }
            ],
            lpaExistingTypeOptions: [
                {
                    label: 'Health Decisions LPA',
                    value: 'health'
                },
                {
                    label: 'Financial Decisions LPA',
                    value: 'financial'
                },
                {
                    label: 'Health & Financial Decisions LPA',
                    value: 'health_financial'
                },
                {
                    label: 'Enduring Power of Attorney',
                    value: 'epa'
                },
                {
                    label: 'Advance Decision',
                    value: 'advance'
                }
            ],
            arrangedByOptions: [
                {
                    label: 'Mortgage Advisor',
                    value: 'mortgage_advisor'
                },
                {
                    label: 'Direct (by client)',
                    value: 'direct_client'
                },
                {
                    label: 'Comparison Website (by client)',
                    value: 'comparison_website_client'
                },
                {
                    label: 'Telesales Firm',
                    value: 'telephone_broker'
                },
                {
                    label: 'Other',
                    value: 'other'
                }
            ]
        }
    }

}